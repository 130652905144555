import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; // This will import the default styles of react-calendar
import '../styles/Leavemanagement.css'; // This will import our custom styles
import { db } from '../config/firebaseconfig';
import { auth } from '../config/firebaseconfig';
import { doc, setDoc ,getDoc } from "firebase/firestore";
import Navigateroutes from "../smallcomponents/Navigate-routes.js";
import MonthYearSelector, { monthsString } from '../smallcomponents/MonthYearSelector';
import { useEffect } from 'react';
function LeaveManagment() {
  const [glinkedOrg,setglinkedorg]=useState(null);
  const [value, setValue] =  useState(new Date());
  const [selected, setSelected] = useState(false);
  const [applyLeave, setApplyLeave]= useState(true);
  const [reason, setReason] = useState('');
  const [showMessage, setShowMessage] = useState(false); // State for managing the visibility of the error message
  const [showSuccessMessage, setShowSuccessMessage] = useState(false); // New state for managing the visibility of the success message
  const [monthYear, setMonthYear] = useState(new Date());
  const [toggleattendanceView, setshowattendancemap] = useState(false);
  const [month, setMonth] = useState(monthYear.getMonth());
  const [year, setYear] = useState(monthYear.getFullYear());
  const onChange = (nextValue) => {
    setValue(nextValue.toLocaleDateString('en-CA'));
    setSelected(true);
  };

  const handleReasonChange = (event) => {
    if (event.target.value.length <= 50) {
      setReason(event.target.value);
    }
  };

  const handleApplyLeave = async () => {
      // Extract the month and year from the date
      let dateValue = new Date(value);
      let monthYear = dateValue.toLocaleString('en-US', { month: 'long', year: 'numeric' });
   
    try {
      const currentTime = new Date();
      const uid = auth.currentUser.uid;
      const docRef = doc(db, "employees", uid);
      // Check if the user has already logged their attendance for the day
      const docSnap = await getDoc(docRef);
      let linkedOrg
      if(docSnap.data().linkedOrg)
      {
       linkedOrg=docSnap.data().linkedOrg;}
      if (docSnap.exists() && docSnap.data().attendance&& docSnap.data().attendance[linkedOrg] && docSnap.data().attendance[linkedOrg][monthYear] && docSnap.data().attendance[linkedOrg][monthYear].leave && docSnap.data().attendance[linkedOrg][monthYear].leave[value] ) {
       // console.log("You have already applied leave for this date.");
        setShowMessage(true); // Show the error message
        setTimeout(() => {
          setShowMessage(false); // Hide the error message after 3 seconds
        }, 3000);
        return;
      }
  
      await setDoc(docRef, {
        attendance: {[linkedOrg]:{
          [monthYear]: {
            leave: {
              [value]: 
              {status :'applied',// Store as a timestamp
                reason: reason
              }
            }
          }
        }}
      }, { merge: true });

      setShowSuccessMessage(true); // Show the success message
      setTimeout(() => {
        setShowSuccessMessage(false); // Hide the success message after 3 seconds
      }, 3000);
    } catch (error) {
     // console.error("Error Applying leave: ", error);
   window.alert("Error Applying leave: "+error)
    }
  };


const Showleaves = () => {
  const [leaves, setLeaves] = useState(null);
  const uid = auth.currentUser.uid;
  const docRef = doc(db, "employees", uid);
  let monthYear = month+' '+year;
 
  useEffect(() => {
    const fetchLeaves = async () => {
      const docSnap = await getDoc(docRef);
      let linkedOrg
      if(docSnap.data().linkedOrg){
       linkedOrg=docSnap.data().linkedOrg;
       setglinkedorg(linkedOrg);
      }
      if (docSnap.data().attendance && docSnap.data().attendance[linkedOrg] && docSnap.data().attendance[linkedOrg][monthYear] && docSnap.data().attendance[linkedOrg][monthYear].leave) {
      setLeaves(docSnap.data().attendance[linkedOrg][monthYear].leave);}
    };

    fetchLeaves();
  }, [docRef, monthYear]);

  return (
    <>
  
    <>
    <table className="empleave-table">
    <tr className="empleave-row">
      <th className="empleave-th">Date</th>
      <th className="empleave-th">Status</th>
      {/* <th className="empleave-th">Reason</th> */}
    </tr>
    {leaves && Object.keys(leaves).map((date) => (
      <tr key={date} className="empleave-row">
        <td>{date}</td>
        <td>{leaves[date].status}</td>
        {/* <td>{leaves[date].reason}</td> */}
      </tr>
    ))}
  </table>
    </>
    </>
  );
};


useEffect(() => {
  const fetchorg = async () => {
    const uid = auth.currentUser.uid;
    const docRef = doc(db, "employees", uid);
    const docSnap = await getDoc(docRef);
    let linkedOrg
    if(docSnap.data().linkedOrg){
     linkedOrg=docSnap.data().linkedOrg;
     setglinkedorg(linkedOrg);
    }
  };

  fetchorg();
}, []);

  return (<>
   
    <div className='Lms'>
      <div className='Lms-header'>
        Apply Leave
      </div>
      
      <Navigateroutes/>
      <div>
      <button className='leavenavigation-button' onClick={()=>{setApplyLeave(true)}}>Apply Leave</button>
   <button className='leavenavigation-button' onClick={()=>{setApplyLeave(false)}}>your leaves</button>
   <div/>
   </div>
   {applyLeave?
  ( glinkedOrg?<>
      <Calendar
        onChange={onChange}
        value={value}
      />
      {selected && (
        <div>
          <p className="confirmation-text">Are you sure you want to apply leave for {value}?</p>
          <textarea 
            value={reason} 
            onChange={handleReasonChange} 
            placeholder="Enter reason (max 50 words)" 
            className="leave-reason"
          />
          <div>
            <button 
              onClick={handleApplyLeave} 
              className="apply-button"
            >
              Apply Leave
            </button>
          </div>
        </div>
      )}
      {showMessage && (
        <div style={{backgroundColor: 'red', color: 'white'}}>
          Leave for this date already exist in system
        </div>
      )}
      {showSuccessMessage && (
        <div style={{backgroundColor: 'green', color: 'white'}}>
          Leave applied for date - {value}
        </div>
      )}</> : <div style={{margin:"20px" ,  color: "red",  fontWeight: "bold"}}>You are not linked to any organization. Check 'your org' tab. </div>
    )
      :<>  <MonthYearSelector 
      month={month} 
      year={year} 
      onMonthChange={(e) => setMonth(e.target.value)} 
      onYearChange={(e) => setYear(e.target.value)} 
      toggleAttendanceView={toggleattendanceView}
    /> <Showleaves></Showleaves></>}
    </div>
   
</>
  );
}

export default LeaveManagment;
