import SetMaplocation from '../smallcomponents/SetonMap.js';


const UserMap=()=>
{
    return(
        <div>
            <SetMaplocation locations={[[31,76],[31.06,76.01]]}/>
        </div>
    ) 
}


export default UserMap;
