import { getDatabase, ref, set, onValue, serverTimestamp } from "firebase/database";
import {realtimeDb} from "../config/firebaseconfig"

// Initialize your Firebase app
// const firebaseApp = initializeApp({ /* your config */ });

// Get a reference to the database service
const db = realtimeDb;

// Create a reference to a location where the server timestamp will be stored
const timestampRef = ref(db, 'serverTimestamp');

// Write the server timestamp to that location
set(timestampRef, {
  timestamp: serverTimestamp()
});

// Create a function to get the server timestamp
function getServerTimestamp() {
  // Return a new Promise
  return new Promise((resolve, reject) => {
    // Attach a listener to the 'value' event
    onValue(timestampRef, (snapshot) => {
      const data = snapshot.val();
      // Resolve the Promise with the timestamp value
      resolve(data.timestamp);
    }, {
      onlyOnce: true,
    });
  });
}

export default getServerTimestamp;

// Usage:
// async function logTimestamp() {
//   const timestamp = await getServerTimestamp();
//   console.log("server timestamp: " + timestamp);
// }
// logTimestamp();
