import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, setDoc,getDoc, getDocs, query, getFirestore,where, collection, addDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { v4 as uuidv4 } from 'uuid';
import '../styles/SelectionPage.css'; // Import the CSS file
import Spinner from "../smallcomponents/spinner.js"
import { set } from 'firebase/database';

function SelectionPage() {

  const navigate = useNavigate();
  const [fetcheduserType,setfetcheduserType]=useState(null);
  const [showModal, setShowModal] = useState(false);
  const [userType, setUserType] = useState(null);
  const [name, setName] = useState('');
  const [step, setStep] = useState(0);
  const [isorgNameExist,setisorgNameExist]=useState(false);
  const [isLoading, setIsLoading] = useState(false); 
  const handleUserTypeSelect = (type) => {
    setUserType(type);
    setStep(1);
  };


useEffect(()=>{
  const fetchdata = async () => {
    const auth = getAuth();
    const db = getFirestore();
    const user = auth.currentUser;
    const docRef = doc(db, "employees", user.uid);
    const docSnap = await getDoc(docRef);
    if(docSnap.data().type)
    {setfetcheduserType(docSnap.data().type)}}
  fetchdata();
},[]);

  const handleNameSubmit = async () => {
    const db = getFirestore();

    if (userType === 'organization') {

      const q = query(collection(db, 'organizations'), where('OrgName', '==', name.toLowerCase()));
     
      const querySnapshot = await getDocs(q);
     // console.log(querySnapshot);
      if (!querySnapshot.empty) {
       // alert('This organization name already exists. Please choose a different name.');
        setisorgNameExist(true);
        return;
      } 
    }
   
    setShowModal(true);
  };

  const handleConfirm = async () => {
    setIsLoading(true);
    const auth = getAuth();
    const db = getFirestore();
    const user = auth.currentUser;

    if (user) {
      let orgId = null;
      if (userType === 'organization'){
        // Generate a random 5-digit number
        const randomNumber = Math.floor(1000 + Math.random() * 9000);

        const userData = {
          type: userType,
          orgId: orgId,
          OrgName: name.toLowerCase(),
          // Add the new field with a hyphen and a random 5-digit number
          OrgNumber: name.toLowerCase() + '-' + randomNumber,
        };
      
        await setDoc(doc(db, 'employees', user.uid), userData, { merge: true });
       
        const data2= { OrgName: userData.OrgName ,
          orgNumber: userData.OrgNumber}

          try {
            const docRef = await addDoc(collection(db, "organizations"), data2);
           // console.log("Document written with ID: ", docRef.id);
        } catch (error) {
           // console.error("Error adding document: ", error);
        }
        


        navigate('/emprequest');
      }
      
    else
    {
      const userData = {
        type: userType,
        name: name.toLowerCase(),
        orgId: null,
        OrgName: null,
      };
      await setDoc(doc(db, 'employees', user.uid), userData, { merge: true });
     navigate('/yourorg');
    }
    }

   setIsLoading(false);
  
  };

  return (
    isLoading? <Spinner/> : fetcheduserType? <div style={{margin:"20px" ,  color: "red",  fontWeight: "bold"}}>You have already Set the preference you can't change it. </div>
    :
    (<div className="selection-page">
     
      {step === 0 && (
        <> <h1>Select User Type</h1>
          <button className="selection-button" onClick={() => handleUserTypeSelect('organization')}>Organization</button>
          <button className="selection-button" onClick={() => handleUserTypeSelect('employee')}>Employee</button>
        </>
      )}
      {step === 1 && (
        <>
           <h1>Set Name</h1>
          <input className='select-inputField' type="text" value={name} onChange={(e) => {setName(e.target.value) ; setisorgNameExist(false)}} placeholder="Enter name" />
          <button className="selection-button" onClick={handleNameSubmit}>Submit</button>
          {isorgNameExist && <p style={{color:'red'}}>This organization name already exists. Please choose a different name.</p>}
        </>
      )}

      {showModal && (
        <div className="modal">
          <p>Are you sure you are an {userType}? You will not be able to change this later.</p>
          <button onClick={handleConfirm}>Yes</button>
          <button onClick={() => setShowModal(false)}>No</button>
        </div>
      )}
    </div>)
  );
}

// Function to generate a random organization ID
function generateOrgId() {
  return uuidv4();
}

export default SelectionPage;
