import '../styles/Spinner.css'

function Spinner() {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh'
        }}>
            <div style={{
                width: '100px',
                height: '100px',
                border: '16px solid #144876',
                borderTop: '16px solid #205d93d2',
                borderRadius: '50%',
                animation: 'spin 2s linear infinite'
            }} />
        </div>
    );
}

export default Spinner;
