import '../styles/Contact.css'
import { useNavigate } from 'react-router-dom';
const Contact =()=>{
    const navigate = useNavigate();
    return(
        <>
        <div style={{display: 'flex', flexDirection: 'row' ,justifyContent: 'center', alignItems: 'center'}}>

          <h1 className="contact-header">Attend<span className="brancolor">D</span>  </h1> 
          <div class ='contact-buttondiv'><button class ='contact-button' onClick={()=>{navigate('/')}}>Home</button></div>
          </div>
        <div style={{textAlign: 'center'}}>
            <h1>Contact</h1>
        </div>
        <div>
        <p style={{textAlign: 'center'}}>For Enquiries or any Feedback</p>
            <p style={{textAlign: 'center'}}>Email us at <h2>attenddcorp@gmail.com</h2></p>
        </div>
        </>
        
    )
}

export default Contact;