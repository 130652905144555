import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import { useEffect } from 'react';
import '../styles/Login.css'; // Import the CSS file from the styles folder
import { auth , user ,db} from '../config/firebaseconfig.js'; // Import the auth object
import { doc, getDoc ,setDoc } from "firebase/firestore";
import {  googleProvider } from '../config/firebaseconfig.js';
import { signInWithPopup } from 'firebase/auth';
import Spinner from "../smallcomponents/spinner.js"
import { signInWithEmailAndPassword,  getAuth, sendPasswordResetEmail,setPersistence, browserLocalPersistence } from "firebase/auth"; // Import the setPersistence and browserLocalPersistence constants
import { set } from 'firebase/database';
import Googlebtn from '../google-ntl.svg';

const Login = ({authfuction}) => {

   // const [user] = useAuthState(auth);
  const [isLoading, setIsLoading] = useState(false); // Initialize the isLoading state isLoading
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isAuthenticated,setisAthenticated]=useState(false);
  const [loginError , setloginError] = useState(null);
  const [isPasswordReset, setIsPasswordReset] = useState(false);
const [isPasswordResetsent, setisPasswordResetsent] = useState(false);
const navigate = useNavigate();


const signInWithGoogle = async () => {
  try {
    const userCredential = await signInWithPopup(auth, googleProvider);
    const user = userCredential.user;

    // Create a reference to the user's document in Firestore
    const userDocRef = doc(db, 'employees', user.uid);

    // Check if a document for this user already exists
    const userDoc = await getDoc(userDocRef);
    if (!userDoc.exists()) {
      // Only create a new document if it does not already exist
      const employeeData = {
        email: user.email,
        id: user.uid,  // The unique ID from Firebase Authentication
        // Add any other details you want to store
      };

      await setDoc(userDocRef, employeeData);
      console.log("User signed up and added to Firestore database");
    }

    navigate('/');
  } catch (error) {
    console.error("Error signing in with Google:", error);
  }
};


  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Set isLoading to true to show the spinner
    try {
      // Set the persistence to local before signing in the user
      await setPersistence(auth, browserLocalPersistence);
      const userCredential=   await signInWithEmailAndPassword(auth,email, password);
      setisAthenticated(true);
      authfuction(true) ;    // call back function used to set state hook value;
      setloginError(null);
      const user = userCredential.user;
      // Get the user's document from Firestore
    const docRef = doc(db, 'employees', user.uid);
    const docSnap = await getDoc(docRef);
if (docSnap.exists()) {
      const userData = docSnap.data();
      if (userData.type==='employee') {
        if(userData.linkedOrg)
        // The user type is already set, so navigate to the user's dashboard
        navigate('/clockin');
       else {
        // The user type is not set, so show the user type selection page
        navigate('/yourorg');
      }
    
    }

      
   else   if (userData.type==='organization') {
       
        navigate('/manageemp');
      }

      else {
        // The user type is not set, so show the user type selection page
        navigate('/select');
      }


    } else {
    //  console.log("No such document!");
    }
    //  console.log("User signed in");
    }
    
    
    catch (error) {
      const errorCode = error.code;
          const errorMessage = error.message;
       if   (errorCode === 'auth/invalid-credential') {
            // Handle case when credential is invalid
           // console.log("Invalid credential.");
            setloginError("Invalid credential.");
          }
      if (errorCode === 'auth/user-not-found') {
        // Handle case when user doesn't exist
        setloginError("No user found with this email.");
   //     console.log("No user found with this email.");
      } else if (errorCode === 'auth/wrong-password') {
        // Handle case when password is incorrect
        setloginError("Wrong password or email.");
    //    console.log("Wrong password or email");
      } else {
        // Handle other errors
        console.log(errorMessage);
      }
    }
    setIsLoading(false); // Set isLoading to false to hide the spinner
  };


  const auth = getAuth();
  
  const sendResetEmail = (email) => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        console.log("Password reset email sent!");
        setisPasswordResetsent(true); // Set the state variable to true to indicate the password reset email has been sent
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        if (errorCode === 'auth/user-not-found') {
          // Handle case when user doesn't exist
       //   console.log("No user found with this email.");
        } else {
        //  console.error("Error sending password reset email: ", errorMessage);
        }
      });
  };
  

    const resetPassword = (<>
         <h1 className="login-title">Reset Password</h1>
    <form className="login-form" onSubmit={(e)=> {e.preventDefault();sendResetEmail(email);}}>
    <div>
    
      <input
        type="email"
        value={email}
        onChange={(e)=>{ handleEmailChange(e)}}
        required
        placeholder="Email"
      />
    </div>
    <button type="submit" >Send reset link</button>
  </form></>)


  return (
    <>
   {isLoading? <Spinner/> :
    <div className="login-container">
      <div className="login-ui">
     
  
      {!isPasswordReset?
   
      (
       <>
           <h1 className="login-title">Sign in to AttendD</h1>
      <button  class="google-btn" onClick={signInWithGoogle}><img src={Googlebtn} alt="Google Sign-In"  style={{width: '100%', height: '100%'}}/></button>
       <h3 style={{textAlign:'center' , color: '#605d5c'}}>or</h3>
      <form className="login-form" onSubmit={handleSubmit}>
        <div>
          <input
            type="email"
            value={email}
            onChange={(e)=>{handleEmailChange(e); setloginError(null);}}
            required
            placeholder="Email"
          />
        </div>
        <div>
          <input
            type="password"
            value={password}
            onChange={handlePasswordChange}
            required
            placeholder="Password"
          />
        </div>
        <button type="submit">Login</button>
      </form></>) : resetPassword}
      
          <h4 style={{color:"red"}}>
        {loginError}
         </h4>
         {!isPasswordReset?
         <Link  className="custom-link" onClick={()=>setIsPasswordReset(true)}>Reset Password</Link> 
         : (<Link onClick={()=>{setIsPasswordReset(false); setisPasswordResetsent(false);}} className="custom-link">Sign in</Link>)}
         {isPasswordResetsent ? <h2>check your email password sent</h2> : null} 
         
         <Link to="/signup" className="custom-link">Create Account</Link>     
    </div>
    </div>
    }
    </>
  );
};

export default Login;
