import { signOut } from 'firebase/auth';
import { auth } from '../config/firebaseconfig';
import { useNavigate } from 'react-router-dom';
import '../styles/Logout.css';

function Logout() {
  // ...
 const navigate = useNavigate();
  const handleLogout = () => {
    auth.signOut()
      .then(() => {
      //  console.log("User signed out successfully");
        navigate("/"); // Navigate to the login page after successful logout
      })
      .catch((error) => {
        //console.error("Error signing out: ", error);
      });
  };


return (<>

<button class='logout-button' onClick={handleLogout}> Logout</button>
</>);

}


export default Logout;

