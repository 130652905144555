import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer,CircleMarker,Tooltip, Marker, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import '../styles/Map.css';
import { map } from 'leaflet';


function UserLocationMap() {
    const [location, setLocation] = useState([51.505, -0.09]); // Default location
    const map = useMap();
    function getPlaceName(lat, lon, callback) {
        var url = "https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=" + lat + "&lon=" + lon;
      
        fetch(url)
          .then(response => response.json())
          .then(data => callback(data.address))
          .catch(error => {});
      }
      
      // Usage:
    
    useEffect(() => {
    
        navigator.geolocation.getCurrentPosition((position) => {
          const { latitude, longitude } = position.coords;
          //console.log("Latitude: ", latitude, "Longitude: ", longitude); // Log the coordinates
          setLocation([latitude, longitude]);
          map.setView([latitude, longitude], 13); // Fly to the user's location
          // getPlaceName(latitude, longitude, function(address) {
          // //  console.log(address);
          // }  );
        });
      
       
    }, [map]);


    const rerenderthecords = () => {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        //console.log("Latitude: ", latitude, "Longitude: ", longitude); // Log the coordinates
        map.setView([latitude, longitude], 13); // Fly to the user's location
      //  console.log("rerendered");
      });
    }
    
    rerenderthecords();
   


      
    return (  <CircleMarker 
      center={location} 
      radius={10} 
      color={'blue'}
      fill={true}
      fillColor={'blue'} 
     fillOpacity={0.5}
    >
      <Tooltip>{'Your location'}</Tooltip>
    </CircleMarker>
  )//<Marker position={location} />;
  }
  


  function Maplocation({onlocationchange}) {
    const [location, setLocation] = useState([0, 0]); // Default location
  
    useEffect(() => {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        setLocation([latitude, longitude]);
        onlocationchange(latitude, longitude); // Pass the location to the parent component
      });
    }, []);
  
    return (
      <MapContainer attributionControl={false} center={location} zoom={13} style={{ height: "40vh", width: "100%" }}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <UserLocationMap />
      </MapContainer>
    );
  }


  
  

export default Maplocation;




