import { useNavigate } from 'react-router-dom';
import '../styles/navigateroutes.css'


const Navigateroutes =()=>{
    const navigate=useNavigate();
   
   
    return(
        <>
        <button className='navigation-button' onClick={()=>navigate('/clockin')}>checkin</button>
        <button className='navigation-button' onClick={()=>navigate('/leave')}>Leave</button>
        <button className='navigation-button' onClick={()=>navigate('/yourorg')}>Your Org</button>
        </>
    )
}



export default Navigateroutes;