import { useEffect ,useState } from "react";
import { auth , db} from '../config/firebaseconfig'; 
import { collection, getDocs,getDoc,setDoc, query, where, doc,  } from "firebase/firestore";
import "../styles/ManageEmp.css"
import { set } from "firebase/database";
import { useNavigate } from 'react-router-dom';
import Logout from "../smallcomponents/Logout.js";

const ManageEmp=()=>
{
 // fetch data from firebase collection 'employee' 
  const navigate = useNavigate();
  const [empRequestsdata , setEmpRequestsdata] = useState(null);
  const [orgName , setOrgName] = useState(null);
  const[orgNumber , setOrgNumber] = useState(null);
  const [statusChange , setstatusChange] = useState(null);
  let localdatacopy;
    const fetchData=async()=>{ 
        const uid = auth.currentUser.uid;
        // fetch Orgname from firebase collection 'employee'
        const docRef = doc(db, 'employees', uid);
      //  console.log(auth.currentUser);
        const docSnap = await getDoc(docRef);
        const orgname = docSnap.data().OrgName;
        setOrgName(orgname);
        setOrgNumber(docSnap.data().OrgNumber);
        const q = query(collection(db, 'requests'), where('orgName', '==', orgname));
        const querySnapshot = await getDocs(q);
        const results = [];
         querySnapshot.forEach((doc) => {
         results.push(doc.data());
    });
   
    setEmpRequestsdata(results);
    
    
    }

    useEffect(()=>{fetchData()},[]);

    const acceptbutton= async (email , index)=>{
        const uid = auth.currentUser.uid;
        const q = query(collection(db, 'requests'), where('userEmail', '==', email));
        const querySnapshot = await getDocs(q);    
        if (!querySnapshot.empty) {
         const docx = querySnapshot.docs[0];
         const docRef = doc(db, 'requests', docx.id);
        setDoc(docRef, { status: "accepted" }, { merge: true });
        
    //    const qtest = query(collection(db, 'employees'), where('orgrequested', '==', "org1"));
    //    const snaptest = await getDocs(qtest);
       const qs = query(collection(db, 'employees'),where('orgrequested', '==', orgName), where('email', '==', email)) ;
       const snap = await getDocs(qs);
        //  for(let i=0;i<snap.docs.length;i++)
        //  {
        //     console.log(snap.docs[i].data());
        //  }
        //  console.log(snap);
         if (!snap.empty) {
            const docx2 = snap.docs[0];      
         const docRefx2 = doc(db, 'employees', docx2.id);        
        setDoc(docRefx2, { linkedOrg: orgName }, { merge: true });
        let results = [...empRequestsdata]; // create a new copy of the array
        results[index] = {...results[index], status: "accepted"}; // modify the copy
        setEmpRequestsdata(results); // update the state with the new array
        }
        }

    
    }
    

    const rejectbutton= async (email , index)=>{
        const q = query(collection(db, 'requests'), where('userEmail', '==', email));
        const querySnapshot = await getDocs(q);    
        if (!querySnapshot.empty) {
         const docx = querySnapshot.docs[0];
         const docRef = doc(db, 'requests', docx.id);
        setDoc(docRef, { status: "rejected" }, { merge: true });
        let results = [...empRequestsdata]; // create a new copy of the array
        results[index] = {...results[index], status: "rejected"}; // modify the copy
        setEmpRequestsdata(results); // update the state with the new array
        }


    }


    const removeEmp= async (email , index)=>
    {
        const q = query(collection(db, 'requests'), where('userEmail', '==', email));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
         const docx = querySnapshot.docs[0];
         const docRef = doc(db, 'requests', docx.id);
        setDoc(docRef, { status: "removed" }, { merge: true });
        }
        const qs = query(collection(db, 'employees'),where('orgrequested', '==', orgName), where('email', '==', email)) ;
        const snap = await getDocs(qs);
        if (!snap.empty) {
           const docx2 = snap.docs[0];
        const docRefx2 = doc(db, 'employees', docx2.id);
       setDoc(docRefx2, { linkedOrg: null }, { merge: true });
       let results = [...empRequestsdata]; // create a new copy of the array
      results[index] = {...results[index], status: "removed"}; // modify the copy
       setEmpRequestsdata(results); // update the state with the new array
       }
    }


    return(
        <div className="manageemp">

<div className="manageemp-parentheader">           
<div className="manageemp-header">
  Manage Employee
</div>
<div className='manage-headerButton'>
<button className='navigateAttendance' onClick={()=>{navigate('/dashboard')}}> Attendance Record</button>
<span style={{ position: 'fixed', top: '20px', right: '30px', width: '10%' }}><Logout></Logout></span>
 </div>
</div> 
<div className='manageemp-infotext'>Share your Orgnumber <span style={{fontWeight: 'bold' , backgroundColor : 'lightgreen'}}> {orgNumber} </span> with your employees so that they can request you to add </div>
        <table className="managemp-table">
            <thead className="className-header">
                <tr>
                <th>useremail</th>
                <th>Action</th>
                <th>status</th>
                </tr>
            </thead>
            <tbody>
                {
                  empRequestsdata?
                  empRequestsdata.map((item ,index)=>{
                    return(
                        item.status!='cancelled'?
                        <tr>
                            
                            <td>{item.userEmail}</td>
                            <td>   
                                {  item.status=="accepted" ? <button onClick={()=>{removeEmp(item.userEmail , index)}}>remove </button>:     
                               (<> <button  onClick={()=>{acceptbutton(item.userEmail , index)}} className="acceptbutton">Accept</button> 

                                <button className="rejectbutton" onClick={()=>rejectbutton(item.userEmail , index)}>Reject</button></>)
                                }
                                </td>
                                <td>{item.status}</td>
                        </tr>: null
                    )
                }) : null
                }
            </tbody>
        </table>

        </div>
    )
    
}

export default ManageEmp;