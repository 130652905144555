import React, { useEffect, useState } from 'react';
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import Login from './Login';
import SelectionPage from './SelectionPage';
import ClockinClockOut from './Clockinclockout';
import Spinner from '../smallcomponents/spinner';
import { useNavigate } from 'react-router-dom';

function MainRoute({ setisAthenticated }) {
  const [loading, setLoading] = useState(true);
  const [userType, setUserType] = useState("nouser");
  const auth = getAuth();
  const [wasinfecth,setwasinfetch]=useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      const user = auth.currentUser;
      if (user) {
        const docRef = doc(getFirestore(), 'employees', user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setUserType(docSnap.data().type);
          setwasinfetch(true);
        }
      }
      setLoading(false);
    };

    fetchData();
  }, [auth]);

  useEffect(() => {
    if(wasinfecth)
      setLoading(false);  
  }, [userType]);

  if (loading) {
    return <Spinner />;
  }

  if (!auth.currentUser) {
    return <Login authfuction={setisAthenticated} />;
  }

  if (!userType) {
    //return <SelectionPage />;
    navigate('/select');
    return null;
  }

  if(userType=="employee"){
 // return <ClockinClockOut />;
 navigate('/clockin');
 return null;
  }

  if(userType=='organization')
  {navigate('/manageemp');
  return null;}
}

export default MainRoute;
