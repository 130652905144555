import { useState , useEffect} from "react";
import '../styles/Empattendancedashboard.css';
import { doc, updateDoc,getDocs ,getDoc , collection ,  query, where} from "firebase/firestore";
import { db } from '../config/firebaseconfig';
import { auth } from '../config/firebaseconfig';
import { utils, writeFile ,write} from 'xlsx';
import FileSaver from 'file-saver';
import MonthYearSelector, { monthsString } from '../smallcomponents/MonthYearSelector';
import SetMaplocation from '../smallcomponents/SetonMap.js';
import React, { useRef, useImperativeHandle, forwardRef } from 'react';
import '../smallcomponents/Logout.js'
import { useNavigate } from 'react-router-dom';
const getAttendaceData=async ()=>
{
  const uid = auth.currentUser.uid;
  const docRef = doc(db, "employees", uid);
  const docSnap = await getDoc(docRef);

}



const EmpAttendanceDashBoard = () => {
  const navigate = useNavigate();
  const testdata = [
    {"id":"1" , "name":"johncena" , "day1":"10 AM - 05" , "day2" :"10 AM - 05", "day3":"10 AM - 05" , "day4" :"10 AM - 05" , "day5":"10 AM - 05" , "day6" :"10 AM - 05", "day7":"10 AM - 05" , "day8" :"10 AM - 05"},
  ];

  const [employeedata, setEmployeeData] = useState(testdata);
  const [toggleattendanceView, settoggleView] = useState(true);
  const [month, setMonth] = useState(monthsString[new Date().getMonth()]); // default to current month
  const [year, setYear] = useState(new Date().getFullYear()); // default to current year
  const [showattendancemap,setshowattendancemap] = useState(false);
  const childRef = useRef();
  


  const handleClick = () => {
    childRef.current.exportexcel();
  }


  return (
    <div>
      <div className={`headertile ${toggleattendanceView ? 'responsive' : ''}`}>Employee Attendance record
      <div className='headerButton'> 
        <button onClick={()=>{settoggleView(true); setshowattendancemap(false)}}>Attendance</button>
        <button onClick={()=>{settoggleView(false);setshowattendancemap(false)}}>Leaves</button>  
        <button onClick={()=>{settoggleView(false);setshowattendancemap(true)}}>Map</button>
        <button onClick={()=>{navigate('/manageemp');}}>Manage Employee</button>
      </div></div>
      <MonthYearSelector 
        month={month} 
        year={year} 
        onMonthChange={(e) => setMonth(e.target.value)} 
        onYearChange={(e) => setYear(e.target.value)} 
        toggleAttendanceView={toggleattendanceView}
      />
      {toggleattendanceView?
       <button className='downloadButton'  onClick={()=>{handleClick()}}>Download Excel</button>:null}
      <div className={`tableContainer ${toggleattendanceView ? 'responsive' : ''}`} >   
        <AttendanceTable ref={childRef} monthYear={`${month} ${year}`} toggleattendanceView={toggleattendanceView} showattendancemap={showattendancemap} />
      </div>
    </div>
  );
};




const AttendanceTable = forwardRef(({ monthYear , toggleattendanceView , showattendancemap },ref) => {
 const [users, setUsers] = useState([]);
 const date = new Date(monthYear);
 const monthNumber = date.getMonth();    // in javascript months are indexed from 0
 const year = date.getFullYear();
 const  [dateKeys,setDateKeys]= useState();
 const [orgName , setOrgName] =useState() ;
 useImperativeHandle(ref,()=>({ exportexcel: ()=>{exportToExcel();}}));
  useEffect(() => {
    const fetchData = async () => {
     // const usersCollection = collection(db, 'employees'); // replace 'users' with your collection name
      const uid = auth.currentUser.uid;
      const docRef = doc(db, "employees", uid);
      const docSnap = await getDoc(docRef);
      setOrgName(docSnap.data().OrgName);
      const q = query(collection(db, 'employees'), where('linkedOrg', '==', docSnap.data().OrgName));
        const userSnapshots = await getDocs(q);
    //  const userSnapshots = await getDocs(usersCollection);
      const usersData = userSnapshots.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setUsers(usersData);
    };
   
    fetchData();
    setDateKeys(setDateArrayKey(monthNumber,year));
  }, []);

 
  useEffect(()=> {setDateKeys(setDateArrayKey(monthNumber,year)); },[monthYear]);



 const Leavedata = () => {
  
  // Use state to manage the status of each leave request
const [status, setStatus] = useState({});
const leaveApproval = async (user,userId, dateKey) => {
  const docRef = doc(db, "employees", userId);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    const userData = docSnap.data();
    if (userData.attendance && userData.attendance[orgName]&& userData.attendance[orgName][monthYear] && userData.attendance[orgName][monthYear].leave && userData.attendance[orgName][monthYear].leave[dateKey]) {
      // Update the status of the specific leave request
      await updateDoc(docRef, {
        [`attendance.${orgName}.${monthYear}.leave.${dateKey}.status`]: 'Approved'
      });
      // Also update the status in the state
    setStatus(prevStatus => ({
      ...prevStatus,
      [`${userId}-${dateKey}`]: 'Approved'
    }));
   
    }
  }
}

  return (
    <table className="leavetable">
      <thead className="">
        <tr className="leaverow">
          <th className="leavecell">Name</th>
          <th className="leavecell">Date</th>
          <th className="leavecell">Status</th>
          <th className="leavecell">Action</th>
        </tr>
      </thead>
      <tbody>
        {users.map((user) => {
           let attendance;
           if(user.attendance && user.attendance[orgName]){
           attendance = user.attendance[orgName][monthYear];}
          if (attendance && attendance.leave) {
            return dateKeys.map((datekey) => {
              if(attendance.leave && attendance.leave[datekey]){
                return (
                  <tr className="leaverow">
                    <td className="leavecell">{user.name}</td>
                    <td className="leavecell">{datekey}</td>
                    <td className="leavecell">{status[`${user.id}-${datekey}`] || attendance.leave[datekey].status}</td>
                    <td className="leavecell"><button onClick={()=>{leaveApproval(user,user.id,datekey)}}>Approve</button></td>
                  </tr>
                );
              }
              return null;
            });
          } else {
            return null;
          }
        })}
      </tbody>
    </table>
  );
}





const calculateLocations=(user)=>{

  const locations=[];
  const locationDateMap = new Map();
    if(user.attendance && user.attendance[orgName] && user.attendance[orgName][monthYear]){
  const attendance = user.attendance[orgName][monthYear];
  if (attendance) {
     dateKeys.map((datekey) => {
      if(attendance[datekey] && attendance[datekey].inlatitude ){

    const  inlatitute=  attendance[datekey].inlatitude;
    const  inlongitude=  attendance[datekey].inlongitude;
    const  inTime = attendance[datekey].in.toDate();

    let  outTime 
    let  outlatitute
    let  outlongitude

    if (attendance[datekey].out){
      outTime = attendance[datekey].out.toDate();
      outlatitute=  attendance[datekey].outlatitude;
      outlongitude=  attendance[datekey].outlongitude;}
      else{
          outTime =inTime;
          outlatitute=inlatitute;
          outlongitude=inlongitude;
      }

    locations.push([inlatitute,inlongitude]);
    locations.push([outlatitute,outlongitude])

    //create a map of datekeys and location
   
    locationDateMap.set(datekey, [[inlatitute,inlongitude],[outlatitute,outlongitude]]);

}})}
}

return locationDateMap;
}




function ParentmapComponent() {
  const [selectedUser, setSelectedUser] = useState(users[0]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selecteduserLocation , setSelecteduserLocation] = useState([]);
  const [locations, setLocations] = useState([]);
 const [locationdateKeyvalue, setLocationDateKeyValue] = useState();

 useEffect(()=>{
const initializefirstUserMap=()=>{
 setSelectedDate(null);
 // Calculate locations based on selected user
 const newLocations = calculateLocations(users[0]);
 setLocations(newLocations);
 //setLocationDateKeyValue(new Map());
 const locationdatemap =calculateLocations(users[0]);
 setLocationDateKeyValue(locationdatemap)}
if(users[0])
 initializefirstUserMap();} ,[] )


  const handleUserChange = (event) => {
    const userId = event.target.value;
    const user = users.find(user => user.id === userId);
    setSelectedUser(user);
    setSelectedDate(null);
    // Calculate locations based on selected user
    const newLocations = calculateLocations(user);
    setLocations(newLocations);
    //setLocationDateKeyValue(new Map());
    const locationdatemap =calculateLocations(user);
    setLocationDateKeyValue(locationdatemap);

  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelecteduserLocation(locationdateKeyvalue.get(date));
    // Calculate locations based on selected date
   // const newLocations = calculateLocationsForDate(selectedUser, date);
   // setLocations(newLocations);
  };

  return (
    
      <div style={{display:"flex"}}>
        <div style={{flex:  1}}>
           
          <div className="select-name-container">

        <select onChange={handleUserChange}>
          {users.map((user, index) => (
            <option key={index} value={user.id}>{user.name}</option>
          ))}
        </select></div>
        {selectedUser && selectedUser.attendance &&selectedUser.attendance[orgName] && selectedUser.attendance[orgName][monthYear] && 
          <div style={{ overflowY: 'scroll', minHeight: '200px' }}> {/* Adjust maxHeight as needed */}
            {Object.keys(selectedUser.attendance[orgName][monthYear]).map((date, index) => (
             date!='leave'? 
             <div 
             key={index} 
             onClick={() => handleDateChange(date)}
             style={{
              cursor: 'pointer',
              padding: '10px',
              margin: '10px 0',
              backgroundColor: date === selectedDate ? '#ADD8E6' : '#f5f5f5', // Changes the background color if the date is selected
              borderRadius: '5px',
            }}
           >
             {date}
           </div>
           :null
            ))}
          </div>
        }
      </div><div style={{flex:  2}}>
      <SetMaplocation locations={selecteduserLocation} />
      </div>
    </div>
  );
}






const exportToExcel = async () => {
  // Create a new workbook
  const wb = utils.book_new();

  // Create an array to hold all the data
  const wsData = [];

  // Create a header row
  const headerRow = ['id', 'name'];
  const daysInMonth = new Date(year, monthNumber + 1, 0).getDate(); // get the number of days in the month
  for (let i = 1; i <= daysInMonth; i++) {
    const date = new Date(year, monthNumber, i);
    const formattedDate = date.toLocaleDateString('en-US', { day: 'numeric', month: 'long', weekday: 'long' });
    headerRow.push(formattedDate);
  }
  wsData.push(headerRow);
   let usercount=0;
  // For each user, add a row to the worksheet data
  users.forEach(user => {

    
   

    // console.log(attendance);
    usercount++;
    const userRow = [usercount, user.name];
    // Add a cell for each day
    for (let i = 1; i <= daysInMonth; i++) {
     
if(  user.attendance &&  user.attendance[orgName] && user.attendance[orgName][monthYear]){
      const attendance = user.attendance[orgName][monthYear];
      
      const datekey = `${year}-${(monthNumber + 1).toString().padStart(2, '0')}-${i.toString().padStart(2, '0')}`;
      if (attendance && attendance[datekey]) {
        const inTime = attendance[datekey].in.toDate();
        const outTime = attendance[datekey].out.toDate();
        const totalTime = outTime - inTime; // Difference in milliseconds
        const totalTimeHours = Math.floor(totalTime / 1000 / 60 / 60); // Convert to hours
        const totalTimeMinutes = Math.floor((totalTime / 1000 / 60) % 60); // Convert to minutes
        
        userRow.push(`${inTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })} - ${outTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })} Total Time: ${totalTimeHours > 0 ? `${totalTimeHours} hr ` : ''}${`${totalTimeMinutes} min`}`);
      } else if (attendance && attendance.leave && attendance.leave[datekey]) {
        const leave = attendance.leave[datekey];
        userRow.push(leave.status || leave);
      } else {
        userRow.push('No Attendance');
      }
}

    else{ userRow.push('No Attendance');}
  
  }

    wsData.push(userRow);
  });

  // // Create a new worksheet from the data and add it to the workbook
  // const ws = utils.aoa_to_sheet(wsData);
  // utils.book_append_sheet(wb, ws, 'Attendance');
  // const filename = `attendance_${monthNumber+1}_${year}.xlsx`;
  // writeFile(wb, filename)
  
// Create a new worksheet from the data and add it to the workbook
const ws = utils.aoa_to_sheet(wsData);
utils.book_append_sheet(wb, ws, 'Attendance');
const filename = `attendance_${monthNumber+1}_${year}.xlsx`;

// Use write instead of writeFile
const wbout = write(wb, {type: 'buffer'});

// Use FileSaver to save the file
FileSaver.saveAs(new Blob([wbout], {type: "application/octet-stream"}), filename);
};

const setDateArrayKey=(month , year)=>{
let datesArray = []; // Initialize an empty array to store the dates
// Note: In JavaScript, months are 0-indexed. January is 0, February is 1, etc.
// Get the first day of the month
let date = new Date(year, month, 1);
// Inside the loop
while (date.getMonth() === month) {
  let day = date.getDate();
  let month = date.getMonth() + 1; // Months are 0 indexed in JS, so add 1 to get the correct month
  let year = date.getFullYear();

  // Format the date as "yyyy-mm-dd"
  let formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

  datesArray.push(formattedDate); // Add the formatted date to the array

  // Go to the next day
  date.setDate(date.getDate() + 1);


}
return datesArray;
  }
const daysOfMonth = Array.from({length: new Date(year, monthNumber+1, 0).getDate()}, (_, i) => i + 1);


  return (
   
    showattendancemap ? <ParentmapComponent/>:
    <>
    {/* <div className="downloadbuttoncontainer">
    <button className='downloadButton' onClick={()=>{exportToExcel();}}> download excel</button>
    </div> */}
   { !toggleattendanceView? <Leavedata></Leavedata> :

<div className="table_1parent">
     <table className="table_1">
      <thead>
      <DayHeaders year={year} month={monthNumber} />
      </thead>
      <tbody>
  {users.map((user ,index) => {

 const today = new Date();
 today.setHours(0, 0, 0, 0); // set time to 00:00:00
if(user.attendance && user.attendance[orgName])
            
{   const attendance = user.attendance[orgName][monthYear];
      if(attendance)   
    return (
      <tr key={user.id}>
        <td>{index+1}</td>
        <td>{user.name}</td>
        {
          
        
        dateKeys.map((datekey) => {
          // Convert datekey to Date object
          const kdate = new Date(datekey);
          if ( attendance[datekey] && attendance[datekey].in) {
            
            const inTime = attendance[datekey].in.toDate();
            var outTime;
           if(attendance[datekey].out){
             outTime = attendance[datekey].out.toDate();}
            else{outTime=inTime;}
            const totalTime = outTime - inTime; // Difference in milliseconds
            const totalTimeHours = Math.floor(totalTime / 1000 / 60 / 60); // Convert to hours
            const totalTimeMinutes = Math.floor((totalTime / 1000 / 60) % 60);
            return (
              <td key={datekey} style={{ lineHeight: '2' }}>
                {inTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })}
                {' - '}
                {outTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })}
                <br />
                <strong>
                  {'Total Time: '}
                  {totalTimeHours > 0 ? `${totalTimeHours} hr ` : ''}
                  {`${totalTimeMinutes} min`} 
                </strong>
                <br/>
                {datekey}
              </td>
            );
          } 
          
          
          else if (attendance.leave && attendance.leave[datekey]) {
            let cellStyle = {};
            let message = '';
            if (attendance.leave[datekey].status === 'Approved') {
              cellStyle = { backgroundColor: 'lightgreen' };
              message = 'Leave Approved';
            } else {
              cellStyle = { backgroundColor: 'lightyellow' };
              message = 'Leave Applied';
            }

            return (
              <td key={datekey} style={cellStyle}>
                {message}
              </td>
            );
          } else {
            // If no attendance data for this day, return an empty cell
            if (kdate.getTime() <= today.getTime()) {
              return <td key={datekey} style={{ backgroundColor: '#FFCCCC' }}>No Attendance</td>;
            } else {
              return <td key={datekey}></td>;
            }
          }
} )
        }
      </tr>
    );
      }
    else
      { return (
        <tr key={user.id}>
        <td>{index+1}</td>
        <td>{user.name}</td>
       {dateKeys.map((datekey) => {
        const kdate = new Date(datekey);
        if (kdate.getTime() <= today.getTime()) {
          return <td key={datekey} style={{ backgroundColor: '#FFCCCC' }}>No Attendance</td>;
        } else {
          return <td key={datekey}></td>;
        }
      })}</tr>
      )}

  })}
</tbody>

      </table></div>
}</>
  );
});

// Use <AttendanceTable monthYear="2024-03" /> to get March 2024 attendance for all users


const getDates = (year, month) => {
  const dates = [];
  let currentDate = new Date(year, month, 1);
  while (currentDate.getMonth() === month) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates;
};

const DayHeaders = ({year, month}) => {
  const days = getDates(year, month); // get dates for the specified month and year

  return (
    <tr>
        <th>No.</th>
        <th>Name</th>
      {days.map((day, index) => (
        <th key={index}>
          {day.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric' })}
        </th>
      ))}
    </tr>
  );
};

// Use <DayHeaders year={2024} month={7} /> in your table for August 2024



export default EmpAttendanceDashBoard;
