import React from 'react';
import '../styles/Empattendancedashboard.css';

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const years = Array.from({length: 50}, (_, i) => new Date().getFullYear() - i); // last 50 years

const MonthYearSelector = ({ month, year, onMonthChange, onYearChange , toggleAttendanceView}) => (
  <div className={`select-container ${toggleAttendanceView ? 'responsive' : 'xxx'}`}>
    <select value={month} onChange={onMonthChange}>
      {months.map((month) => <option key={month} value={month}>{month}</option>)}
    </select>
    <select value={year} onChange={onYearChange}>
      {years.map((year) => <option key={year} value={year}>{year}</option>)}
    </select>
  </div>
);

// constants.js
export const monthsString = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];


export default MonthYearSelector;
