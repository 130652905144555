import { useState, useEffect } from "react";
import '../styles/Clockinclockout.css';
import { doc, setDoc ,getDoc ,serverTimestamp } from "firebase/firestore";
import { db } from '../config/firebaseconfig';
import { auth } from '../config/firebaseconfig'; // import the auth object
import Logout from "../smallcomponents/Logout";
import getServerTimestamp from "../smallcomponents/time.js";
import Maplocation from '../smallcomponents/Map.js';
import Navigateroutes from "../smallcomponents/Navigate-routes.js";

const ClockinClockOut = () => {
    const [userName, setUserName] = useState(null);
    const [tdate, setTdate] =  useState(); //useState(new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().split('T')[0]); // Set to current date
    const [monthYear, setMonthYear] =useState(); //useState(new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'long' })); // Set to current month and year
    const [clockInTime, setClockInTime] = useState(null);
    const [clockOutTime, setClockOutTime] = useState(null);
    const [clockedOut, setClockedOut] = useState(false);
    const [isclockedin, setisClockedin] = useState(false);
    const [totalTime, setTotalTime] = useState(null);
    const [linkedOrg, setLinkedOrg] = useState(null);
    const [isclocking, setisclocking] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
        const servtime = await getServerTimestamp();

         //const servtime = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString();
         const currentDate = new Date(servtime);
       
            setTdate(currentDate.toISOString().split('T')[0]);
            setMonthYear(currentDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long' }));
        };    
        fetchData();
       
      }, []);
      


    async function initialiseData() {
      

        const uid = auth.currentUser.uid;
        const docRef = doc(db, "employees", uid);
        const docSnap = await getDoc(docRef);
        setUserName( docSnap.data().name);
        setLinkedOrg(docSnap.data().linkedOrg);
        const linkedOrg= docSnap.data().linkedOrg;
        if (docSnap.exists() && docSnap.data().attendance && docSnap.data().attendance[linkedOrg] && docSnap.data().attendance[linkedOrg][monthYear] && docSnap.data().attendance[linkedOrg][monthYear][tdate]) {
            setClockInTime((docSnap.data().attendance[linkedOrg][monthYear][tdate].in).toDate());
            if(docSnap.data().attendance[linkedOrg][monthYear][tdate].out)
            setClockOutTime((docSnap.data().attendance[linkedOrg][monthYear][tdate].out).toDate());
          
        }
       
        
        
    }
    
    useEffect(() => {
      initialiseData();
      }, [tdate, monthYear,clockedOut ,isclockedin]);
    
 
      const handleClockIn = async () => {
        setisclocking(true);
        try {
          const currentTime = new Date();
      
          const uid = auth.currentUser.uid;
          const docRef = doc(db, "employees", uid);
      
          // Check if the user has already logged their attendance for the day
          const docSnap = await getDoc(docRef);
          if (docSnap.exists() && docSnap.data().attendance && docSnap.data().attendance[linkedOrg]&& docSnap.data().attendance[linkedOrg][monthYear] && docSnap.data().attendance[linkedOrg][monthYear][tdate]) {
         //   console.log("You have already logged your attendance for today.");
         window.alert("You have already logged your attendance for the day.");
            return;
          }
      
          async function captureClockindata(position) {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            setClockInTime(currentTime);
            await setDoc(docRef, {
              attendance: {
                [linkedOrg]:{
                [monthYear]: {
                  [tdate]: {
                    in: serverTimestamp(), // Store as a timestamp
                    inlatitude: latitude,
                    inlongitude: longitude
                  }
                }
              }}
            }, { merge: true }).then(() => { setisClockedin(true) });
          }
      
          // Check if geolocation is supported
          if (!navigator.geolocation) {
          //  console.log("Geolocation is not supported by your browser.");
          window.alert("Geolocation is not supported by your browser or device")
            return;
          }
      
          // Get the user's current position
          navigator.geolocation.getCurrentPosition(async (position) => 
            captureClockindata(position),
            (error) => {
             // console.error("Error getting location: ", error);
              if (error.code === error.PERMISSION_DENIED) {
                window.alert("You need to allow location access to mark attendance.");
              }
            }
          );
          setShowConfirm(false);
        } catch (error) {
         // console.error("Error updating document: ", error);
        }
        setisclocking(false);
      }
      
    
      const handleClockOut = async () => {
        setisclocking(true);
        try {
          const currentTime = new Date();
      
          const uid = auth.currentUser.uid;
          const docRef = doc(db, "employees", uid);
      
          // Check if the user has already logged their attendance for the day
          const docSnap = await getDoc(docRef);
          if (!docSnap.exists() || !docSnap.data().attendance || !docSnap.data().attendance[linkedOrg]  || !docSnap.data().attendance[linkedOrg][monthYear] || !docSnap.data().attendance[linkedOrg][monthYear][tdate] || docSnap.data().attendance[linkedOrg][monthYear][tdate].out) {
        //    console.log("You have already logged your attendance for today or you haven't clocked in yet.");
           window.alert("You have already logged your attendance for today or you haven't clocked in yet.")
        return;
          }
      
          // Check if geolocation is supported
          if (!navigator.geolocation) {
          //  console.log("Geolocation is not supported by your browser.");
          window.alert("Geolocation is not supported by your browser.");
            return;
          }


          async function captureClockoutdata(position) {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
      
            setClockOutTime(currentTime);
            await setDoc(docRef, {
              attendance: {[linkedOrg]:{
                [monthYear]: {
                  [tdate]: {
                    out: serverTimestamp(), // Store as a timestamp
                    outlatitude: latitude,
                    outlongitude: longitude
                  }
                }
              }}
            }, { merge: true }).then(() => {
              // After the document is written, read it back
              setClockedOut(true);
            });
      
            const timeSpent = new Date(currentTime - clockInTime);
            setTotalTime(`Total Time Spent: ${timeSpent.getUTCHours()} hours, ${timeSpent.getUTCMinutes()} minutes, ${timeSpent.getUTCSeconds()} seconds`);

          }
      
          // Get the user's current position
          navigator.geolocation.getCurrentPosition(async (position) => {
            captureClockoutdata(position)  }, (error) => {
           // console.error("Error getting location: ", error);
          
            if (error.code === error.PERMISSION_DENIED) {
              window.alert("You need to allow location access to mark attendance.");
            }

            else{ window.alert("Error getting location: "+ error);}
          
        });
        } catch (error) {
         // console.error("Error updating document: ", error);
        }

        setisclocking(false);
      }
      
    
    
    // const generateMockData = (userName, monthYear, startDate, endDate) => {
    //     console.log("in mock data");
    //     let attendance = {};
    //     let docRef = doc(db, "employees", userName);
    //     for(let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
    //         let tdate = `${d.getFullYear()}-${String(d.getMonth()+1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}`; // format date as YYYY-MM-DD
    //         let inTime = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 9).getTime(); // 9 AM
    //         let outTime = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 17).getTime(); // 5 PM
    //         attendance[monthYear] = {
    //             ...attendance[monthYear],
    //             [tdate]: {
    //                 in: inTime,
    //                 out: outTime
    //             }
    //         };
    //     }
    //     return setDoc(docRef, { name: userName, attendance , linkedOrg:"jcorp"}, { merge: true });
    // };
    

    // let startDate = new Date(2024, 1, 13); // start date (1st Jan 2023)
    //  let endDate = new Date(2024, 1, 15); // end date (31st Jan 2023)
    // for (let i=0 ;i< 10 ; i++){
    //  generateMockData('user'+i, 'February 2024', startDate, endDate);

    // }
    

   const [longitude,setLongitude] = useState(null);
   const [latitude,setLatitude] = useState(null);

  
   const setPositionCords = (latitude,longitude) => {
        setLatitude(latitude);
        setLongitude(longitude);
    }
    
    const [refresh,setRefresh] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [showConfirmClockOut, setShowConfirmClockOut] = useState(false);
    return (
        <div>
            <div className="header-clockin">
            <div>Name  :  {userName?userName.charAt(0).toUpperCase() + userName.slice(1):null}  </div>
            <div>Date      :  {tdate}</div>
              <div>  <Logout></Logout></div>
            </div>
            <Navigateroutes/>
            <div className="clockinclockoutContainer">
                <div className="clockinmap">
            <Maplocation onlocationchange={setPositionCords}></Maplocation></div>
            <button className='relocateButton' onClick={()=>{setRefresh(prevRefresh => !prevRefresh); }}>Re-locate</button>
  <div className="ClockinClockOuttextcontainer">
                    {!clockInTime? isclocking?<div>Loading...</div> :
                    showConfirm ? (
                      linkedOrg?
                      <div>
                        <div class="clockin-confirmtext">Confirm Check-in</div>
                        <button id="clockin-button" style={{backgroundColor: "#1f5f97"}} onClick={handleClockIn}>Yes</button>
                        <button id="clockin-button" style={{backgroundColor: "#1f5f97"}}onClick={() => setShowConfirm(false)}>No</button>
                      </div>: <div style={{margin:"20px" ,  color: "red",  fontWeight: "bold"}}>You are not linked to any organization. Check 'your org' tab. </div>
                    ) : (
                      <button id="clockin-button" onClick={() => setShowConfirm(true)}>
                        Check-in
                      </button>
                    ) : null }
                           
                           {!clockOutTime && clockInTime ? isclocking?<div>Loading...</div> :
        showConfirmClockOut ? (
          <div>
            <div className="clockin-confirmtext">Confirm Check-out</div>
            <button  id="clockout-button" style={{backgroundColor: "#1f5f97"}} onClick={handleClockOut}>Yes</button>
            <button  id="clockout-button" style={{backgroundColor: "#1f5f97"}}onClick={() => setShowConfirmClockOut(false)}>No</button>
          </div>
        ) : (
          <button id="clockout-button" onClick={() => setShowConfirmClockOut(true)}>
            Check-out
          </button>
        ) : null
         }
                    <div style={{ display: 'flex',  flexDirection: 'column' ,alignItems: 'flexstart' , marginTop:'5%'}}>
                    <div className="clockedintime">{clockInTime ? `Check-in time: ${clockInTime.toLocaleString()}` : null}</div> 
                    {clockOutTime && <div className='clockin-line'></div>}
                    <div className="clockedouttime">{clockOutTime ? `Check-out time: ${clockOutTime.toLocaleString()}` : null}</div>
                    </div>
                </div>
              
            </div>
            {clockedOut && <div className="totalTime">
                {totalTime}
            </div>}
        </div>
    )
}


export default ClockinClockOut;




