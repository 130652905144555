import React, { useState ,useEffect } from 'react';
import { getFirestore, collection, getDocs,getDoc, query, where, setDoc, doc, updateDoc } from "firebase/firestore";
import '../styles/Searchorg.css'; // Import your CSS file
import { auth } from '../config/firebaseconfig'; 
import Navigateroutes from "../smallcomponents/Navigate-routes.js";

function SearchOrg() {
  const [orgName, setOrgName] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);
  const [requestStatus, setRequestStatus] = useState(null);
  const [requestDocId, setRequestDocId] = useState(null);
  const [orgNumber,setorgNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false); // New state for loading
  const [isRequestLoading, setIsRequestLoading] = useState(false); // New state for request loading

  useEffect(() => {
    const fetchRequestStatus = async () => {
      const db = getFirestore();
      const userEmail = auth.currentUser.email;
      //console.log(userEmail);
      
      const q = query(collection(db, 'requests'), where('userEmail', '==', userEmail));
      const querySnapshot = await getDocs(q);
     
      querySnapshot.forEach((doc) => {
        if (doc.exists()) {
          setRequestStatus(doc.data().status);
          setRequestDocId(doc.id);
          setOrgName(doc.data().orgName);
        }
      });
    };
  
    fetchRequestStatus();
  }, []);
  
  const handleSearch = async () => {
    setIsLoading(true); // Set loading to true at the start of the search
    setHasSearched(true);
    const db = getFirestore();
    const q = query(collection(db, 'organizations'), where('orgNumber', '==', orgNumber.trim().toLowerCase()));
    const querySnapshot = await getDocs(q);

    const results = [];
    querySnapshot.forEach((doc) => {
      results.push(doc.data());
    });

    setSearchResults(results);
    setIsLoading(false); // Set loading to false after the search is complete
  };

  const handleJoinRequest = async (orgName) => {
    setIsRequestLoading(true); // Set loading to true at the start of the request
    const db = getFirestore();
    const uid = auth.currentUser.uid;
    const docRef1 = doc(db, "employees", uid);
    const docSnap = await getDoc(docRef1);
  
     await setDoc(doc(db, 'employees', uid), {
      orgrequested : orgName 
     }, {merge :true})

    // Use the user's uid as the document ID
    const docRef = doc(db, 'requests', uid);
    await setDoc(docRef, {
      userEmail: docSnap.data().email, // replace this with the actual user email
      orgName: orgName,
      status: 'requested'
    }, { merge: true });
  
    //console.log(`Join request sent to ${orgName}, doc ID: ${docRef.id}`);
    setRequestStatus('requested');
    setRequestDocId(docRef.id);
    setOrgName(orgName);
    setIsRequestLoading(false); // Set loading to false after the request is complete
  };
  

  const handleRevertRequest = async () => {
    setIsRequestLoading(true); // Set loading to true at the start of the revert request
    const db = getFirestore();
    const requestDoc = doc(db, 'requests', requestDocId);
    await updateDoc(requestDoc, {
      status: 'cancelled'
    });

    //console.log(`Join request cancelled, doc ID: ${requestDocId}`);
    setRequestStatus('cancelled');
    setIsRequestLoading(false); // Set loading to false after the revert request is complete
  };

  return (
    <div className="employee-dashboard">
      <div className="searchorg-header">
        Your Org
      </div>
      <div> <Navigateroutes/></div>
      <input type="text" value={orgNumber} onChange={(e) => setorgNumber(e.target.value)} placeholder="Enter organization name" className="search-input" />
      <button onClick={handleSearch} className="search-button">Search</button>
    
    {isLoading ? (
      <p style={{color:'black'}}>Searching...</p>
    ) : isRequestLoading ? (
      <p style={{color:'black'}} >Sending request...</p>
    ) :(
      <>
        { requestStatus==='requested' ?  <>
                    <p style={{color:'black' , margin:'10px'}}>You have sent a request to <span className='requestedorg'>{orgName}</span>.</p>
                    <button onClick={handleRevertRequest} className="join-button">Cancel</button>
                  </>: requestStatus==='rejected' && !hasSearched ?  <p>Request rejected check the org name<button onClick={() => handleJoinRequest(orgName)} className="join-button">Resend</button></p>:  
                   requestStatus==='accepted' ?  <p style={{color:'black' , margin:'10px'}}>You are added to organisation {orgName}.</p>:   
                  hasSearched && (searchResults.length > 0 ? (
            searchResults.map((result, index) => (
              <div key={index} className="org-info">
              <span className='searchedorgname'> {result.OrgName} </span>
             {  requestStatus === 'requested' ? (
                  <>
                    <p>You have sent a request to {result.OrgName}.</p>
                    <button onClick={handleRevertRequest} className="join-button">Revert Request</button>
                  </>
                ) :
                requestStatus === 'accepted' ? (
                  <p>You are added to organisation {result.OrgName}.</p>
                ):
                (requestStatus==='rejected') && result.OrgName===setOrgName ?   <>Request rejected check the org name<button onClick={() => handleJoinRequest(result.OrgName)} className="join-button">Resend</button></>
              :
               (
                  <button onClick={() => handleJoinRequest(result.OrgName)} className="join-button">Join</button>
                ) 
        }
         
              </div>
            ))
          ) : (
            <p className="no-org-message">No such organisation name exists. Please ask your organisation for the correct name.</p>
          ))}
      </>
    )}

    </div>
  );
}

export default SearchOrg;
