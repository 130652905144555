import { useNavigate } from 'react-router-dom';
import '../styles/Home.css'

const Home = () => {
    const navigate = useNavigate();

    const handleSignInClick = () => {
        navigate('/login');
    };

    const handleSignUpClick = () => {
        navigate('/signup');
    };

    return (
        <div className="home">
          <div className= "home-container">
            <div className='home-headerArea'>         
            <h1 className="home-header">Attend<span className="brancolor">D</span></h1> 
           <div className='home-headerbutton-container'> <button className="home-headerbutton" onClick={()=>{navigate('/contact')}}>Contact</button>    </div>       
            </div>
            <div className='home-bodyArea'>
            <p>Solution for your Attendance needs</p>
            <div className='home-buttonArea'>
           
            <button className="home-button" onClick={handleSignInClick}>Sign in</button>
            <button className="home-button" onClick={handleSignUpClick}>Create Account</button>
            </div>
            </div>
            </div>
        </div>
    );
}

export default Home;
