import { serverTimestamp } from "firebase/firestore";
import { useState } from "react";
import '../styles/Login.css';
import { Link } from 'react-router-dom';
import { db ,googleProvider} from '../config/firebaseconfig'
import { getAuth, signInWithPopup,createUserWithEmailAndPassword } from "firebase/auth";
import {  doc, setDoc ,getDoc } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import Spinner from "../smallcomponents/spinner.js"
import Googlebtn from '../google-ntl.svg';


const auth = getAuth(); 
const Signup =()=>
{
   const navigate= useNavigate();
   const [username , setUsername] = useState('');
   const [password, setPassword] = useState('');
   const [confirmPass, setConfirmPass]= useState('');
   const [passMatch , setPassMatch]= useState(true);
   const [signuperror , setSignuperror]=useState(null);
   const [isLoading, setIsLoading] = useState(false); // Initialize the isLoading state isLoading

   const submit = (event) => {
    event.preventDefault();
  //   if (password !== confirmPass) {
  //     setPassMatch(false);
  //     console.log("not matched");
  //   }
  //   else {signup(username,password,'User');
  //  console.log(" matched");
  // }
  signup(username,password,'User');
  };
  
  const signInWithGoogle = async () => {
    try {
      const userCredential = await signInWithPopup(auth, googleProvider);
      const user = userCredential.user;
  
      // Create a reference to the user's document in Firestore
      const userDocRef = doc(db, 'employees', user.uid);
  
      // Check if a document for this user already exists
      const userDoc = await getDoc(userDocRef);
      if (!userDoc.exists()) {
        // Only create a new document if it does not already exist
        const employeeData = {
          email: user.email,
          id: user.uid,  // The unique ID from Firebase Authentication
          // Add any other details you want to store
        };
  
        await setDoc(userDocRef, employeeData);
       // console.log("User signed up and added to Firestore database");
      }
  
      navigate('/');
    } catch (error) {
    //  console.error("Error signing in with Google:", error);
    }
  };



  const signup = async (email, password, name) => {
    setIsLoading(true); 
    try {
      // Create a new user with Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      // Create a new entry in the Firestore database for the user
      const employeeData = {
        name: name,
        email: email.toLowerCase(),
        id: user.uid,  // The unique ID from Firebase Authentication
        createdAt : serverTimestamp()
        // Add any other details you want to store
      };
  
      await setDoc(doc(db, 'employees', user.uid), employeeData);
      setSignuperror(null);
     // console.log("User signed up and added to Firestore database");
      navigate('/select');
    } 
    catch (error) {
     
        const errorCode = error.code;
        const errorMessage = error.message;
        if (errorCode === 'auth/email-already-in-use') {
          // Handle case when email is already in use
       //   console.log("An account already exists with this email.");
          setSignuperror("An account already exists with this email.");
        
        } else {
          // Handle other errors
        //  console.log(errorMessage);
          //setSignuperror(errorMessage);
        }
      
    }

    setIsLoading(false);
  };
  
  

  

    return(
    <>
  {    isLoading? <Spinner/> :
    
    <div className='signup-container'>
    
    <div>
       <h1 className='signupTitle'> Create account</h1>
       <button  class="google-btn" onClick={signInWithGoogle}><img src={Googlebtn} alt="Google Sign-In"  style={{width: '100%', height: '100%'}}/></button>
       <h3 style={{textAlign:'center' , color: '#605d5c'}}>or</h3>
        </div>
    <div >
        <form className='SignUpForm' onSubmit={submit} >  
         <input placeholder="Email" onChange={(e)=>{setUsername(e.target.value) ;setSignuperror(null);}}></input>   
        <input placeholder='Password' type="password"   onChange={(e)=>{setPassword(e.target.value)}}></input>
        <button type="submit">Create account</button>
        </form>
        {passMatch ? null : <h1>not matched</h1>}     
    </div>
    <span className="signup-alreadytext">Already have an Account?</span>
    <Link className="custom-link" to="/login">Sign In</Link>
        <h4 style={{color:"red"}}>{signuperror}</h4>
    </div>}
    </>
    )
}

export default Signup;



// const date = '20240312'; // Replace with the date
// const attendanceData = {
//   in: '09:00',
//   out: '17:00'
// };

// const employeeData = {
//   name: 'Employee Name 1',
//   email: 'employee1@example.com',
//   attendance: {
//     '20240310': {
//       in: '09:00',
//       out: '17:00'
//     },
//     '20240311': {
//       in: '09:30',
//       out: '17:30'
//     }
//     // More dates...
//   }
// };