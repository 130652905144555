// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore , connectFirestoreEmulator } from "firebase/firestore";
import { GoogleAuthProvider } from "firebase/auth";
import { getDatabase } from "firebase/database";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD8Ne1cM2dw9FTmQg8c4iYiz810-_taOhg",
  authDomain: "attendance-app-01.firebaseapp.com",
  projectId: "attendance-app-01",
  storageBucket: "attendance-app-01.appspot.com",
  messagingSenderId: "1057514320489",
  appId: "1:1057514320489:web:a76dfb927cc37b35ac68f8",
  measurementId: "G-4T9NBRQRME"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);




let db = getFirestore(app);

if (process.env.NODE_ENV === 'development') {
  connectFirestoreEmulator(db, 'localhost', 8080);
}

export { db };
//export const db = getFirestore(app);






export const auth = getAuth(app);
export const realtimeDb = getDatabase(app);
export const googleProvider = new GoogleAuthProvider();
// Connect to Firestore Emulator if in development environment
