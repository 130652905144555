import React from 'react';
import { MapContainer, CircleMarker, TileLayer, useMap, Tooltip } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { useEffect } from 'react';
import L from 'leaflet';


// Create a new Leaflet Icon instance
// const myIcon = new L.Icon({
//   iconUrl: markerimg,
//   iconSize: [25, 41],
//   iconAnchor: [12, 41],
// });

// function getRandomColor() {
//   const letters = '0123456789ABCDEF';
//   let color = '#';
//   for (let i = 0; i < 6; i++) {
//     color += letters[Math.floor(Math.random() * 16)];
//   }
//   return color;
// }

// function getRandomRadius() {
//   return Math.floor(Math.random() * 50) + 1; // generates a random number between 1 and 50
// }

function DbMarker({ dbLatitude, dbLongitude , color , radius , text}) {
  const map = useMap();
  useEffect(() => {
    map.setView([dbLatitude, dbLongitude], 13);
  }, [dbLatitude, dbLongitude, map]);

  return (
    <CircleMarker 
      center={[dbLatitude, dbLongitude]} 
      radius={radius} 
      color={color}
      fill={true}
      fillOpacity={0.5}
    >
      <Tooltip>{text}</Tooltip>
    </CircleMarker>
  );
}

function SetMaplocation({ locations }) {
  return (
    <MapContainer attributionControl={false} center={locations[0]} zoom={18} style={{ height: "100vh", width: "100%" }}>
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      {locations.map((location, index) => (
     index === 0 ? <DbMarker key={index} dbLatitude={location[0]} dbLongitude={location[1]} color='green' radius={20} text='in location'/> :   <DbMarker key={index} dbLatitude={location[0]} dbLongitude={location[1]} color='red' radius={15} text={"out location"}/>
      ))}
    </MapContainer>
  );
}

export default SetMaplocation;
