import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/Login';
import Signup from './components/Signup';
import Clockinclockout from './components/Clockinclockout';
import { useState ,useEffect } from 'react';
import { auth } from './config/firebaseconfig.js';
import Empattendancedashboard from './components/EmpAttendanceDashBoard.js'
import LeaveManagment from './components/Leavemanagement.js';
// import Empadd from './components/Empadd.js';
import MainRoute from './components/MainRoute.js';
import SelectionPage from './components/SelectionPage.js';
import SearchOrg from './components/SearchOrg.js';
import ManageEmp from './components/Manageemp.js';
import HomePage from './components/HomePage.js';
import Spinner from './smallcomponents/spinner.js';
import Maplocation from './smallcomponents/Map.js';
import UserMap from './components/UserMap.js';
import Contact from './components/Contact.js';





function App() {
 
  const [isAuthenticated,setisAthenticated]=useState(null);
  
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setisAthenticated(!!user); // !! coerces user to a boolean
    //  console.log("authenticated :"+isAuthenticated);
   
    }); 
  
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);



  
  useEffect(() => {
    //console.log("authenticated :"+isAuthenticated);
}, [isAuthenticated]);


if (isAuthenticated === null) {
  return <> <Spinner /></>
}

return (
  <div className="App">
    <Router>
      <Routes>
        <Route path='/' element={<HomePage/>} />
        <Route path='/contact' element={<Contact/>} />
        <Route path="/login" element={<MainRoute setisAthenticated={setisAthenticated} />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/clockin" element={isAuthenticated ? <Clockinclockout /> : <Navigate to="/login" />} />
        <Route path='/dashboard' element={isAuthenticated ?<Empattendancedashboard/>: <Navigate to="/login" />}/>
        <Route path='/leave' element={isAuthenticated ?<LeaveManagment/>: <Navigate to="/login" />}/>
        {/* <Route path='/add' element={<Empadd/>} /> */}
        <Route path='/select' element={isAuthenticated ?<SelectionPage/>: <Navigate to="/login" />} />
        <Route path='/yourorg' element={isAuthenticated ?<SearchOrg/>: <Navigate to="/login" />}></Route>
        <Route path='/emprequest' element={isAuthenticated ?<ManageEmp/>: <Navigate to="/login" />}></Route>
        <Route path='/manageemp' element={isAuthenticated ?<ManageEmp/>: <Navigate to="/login" />}></Route>
        <Route path='/map' element={isAuthenticated ?<UserMap/>: <Navigate to="/login" />} />
        <Route path='*' element={<HomePage/>} />
        
        {/* <Route path='/login' element={<Login authfuction={(value)=>{setisAthenticated(value)}}/>} /> */}
      </Routes>
    </Router>
  </div>
);
}

export default App;
